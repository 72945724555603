import { differenceInDays, format, formatDistance, parseISO } from 'date-fns'

export const convertMinToHours = (minute: number) => {
  if (minute) {
    let hours = Math.floor(minute / 60)
    let minutes = minute % 60
    if (hours > 0 && minutes > 0) {
      return `${hours}h ${minutes}Min`
    }
    if (hours == 0 && minutes > 0) {
      return `${minutes}Min`
    }
    if (hours > 0 && minutes == 0) {
      return `${hours}h`
    }
  } else {
    return null
  }
}
export const convertMintoDays = (minute: number, shiftHours: number) => {
  let days = Math.floor(minute / (shiftHours / 60) / 60)
  let hours = Math.floor((minute / 60) % (shiftHours / 60))
  let minutes = minute % 60

  if (days > 0 && hours > 0) {
    return `${days}d ${hours}h`
  }
  if (days > 0 && hours == 0) {
    return `${days}d`
  }
  if (hours > 0 && days == 0) {
    return `${hours}h `
  }
  return null
}
export const formatDate = (date: string) => {
  const result = parseISO(date)
  return date ? format(result, 'dd MMM yyyy') : 'NA'
}

export const convertDate = (date: string) => {
  const result = parseISO(date)
  return date ? format(result, 'dd/MM/yyyy hh:mm a') : 'NA'
}

export const convertDateToEng = (date: string) => {
  const result = parseISO(date)
  return date ? format(result, 'dd MMM yyyy hh:mm a') : 'NA'
}

export const dateFormat = (date: string) => {
  const result = parseISO(date)
  return date ? format(result, 'dd/MM/yyyy') : 'NA'
}

export const DateFormat = (date: string) => {
  const result = parseISO(date)
  return date ? format(result, 'dd/MM/yyyy') : 'NA'
}

export const convertDateToTime = (date: string) => {
  const result = parseISO(date)
  return date ? format(result, 'hh:mm a') : 'NA'
}

export const dateDifferences = date => {
  var DateDifferences = formatDistance(new Date(), Date.parse(date), {
    includeSeconds: true,
  })
  return DateDifferences
}

export const timeDiff = (startTime: any, endTime: any) => {
  var DateDifferences = differenceInDays(new Date(startTime), new Date(endTime))
  return DateDifferences
}

export function timeElapsed(_datetime: Date, _now: Date) {
  var datetime = new Date(_datetime).getTime()
  var now = new Date(_now).getTime()

  //   if (isNaN(datetime)) {
  //     return ' on ' + _datetime;
  //   }

  if (datetime < now) {
    var milisec_diff = now - datetime
  } else {
    var milisec_diff = datetime - now
  }

  var days = Math.floor(milisec_diff / 1000 / 60 / (60 * 24))
  var hours = Math.floor(milisec_diff / (1000 * 60 * 60) - days * 24)
  var minutes = Math.floor(
    milisec_diff / (1000 * 60) - days * 24 * 60 - hours * 60
  )

  if (minutes == 0 && hours > 0) {
    return `${hours}h`
  }
  if (hours == 0 && minutes > 0) {
    return `${minutes}m`
  }
  return `${hours}h ${minutes}m`
}

export function TimeElapsedHrs(_datetime: Date, _now: Date) {
  var datetime = new Date(_datetime).getTime()
  var now = new Date(_now).getTime()

  //   if (isNaN(datetime)) {
  //     return ' on ' + _datetime;
  //   }

  if (datetime < now) {
    var milisec_diff = now - datetime
  } else {
    var milisec_diff = datetime - now
  }

  var days = Math.floor(milisec_diff / 1000 / 60 / (60 * 24))
  var hours = Math.floor(milisec_diff / (1000 * 60 * 60) - days * 24)
  var minutes = Math.floor(
    milisec_diff / (1000 * 60) - days * 24 * 60 - hours * 60
  )

  if (minutes == 0 && hours > 0) {
    return `${hours} hours`
  }
  if (hours == 0 && minutes > 0) {
    return `${minutes} mins`
  }
  return `${hours} hours ${minutes} mins`
}

export function timeElapsedByMin(_datetime: Date, _now: Date) {
  var datetime = new Date(_datetime).getTime()
  var now = new Date(_now).getTime()

  //   if (isNaN(datetime)) {
  //     return ' on ' + _datetime;
  //   }

  if (datetime < now) {
    var milisec_diff = now - datetime
  } else {
    var milisec_diff = datetime - now
  }
  var minutes = Math.floor(milisec_diff / (1000 * 60))
  if (isNaN(minutes)) minutes = 0

  return minutes
}

export function LastIndex(_datetime: any, _now: any) {
  var datetime = new Date(_datetime).getTime()
  var now = new Date(_now).getTime()

  //   if (isNaN(datetime)) {
  //     return ' on ' + _datetime;
  //   }

  if (datetime < now) {
    var milisec_diff = now - datetime
  } else {
    var milisec_diff = datetime - now
  }

  var days = Math.floor(milisec_diff / 1000 / 60 / (60 * 24))
  var hours = Math.floor(milisec_diff / (1000 * 60 * 60) - days * 24)
  var minutes = Math.floor(
    milisec_diff / (1000 * 60) - days * 24 * 60 - hours * 60
  )
  return days
}

export function timeElapsedByDays(_datetime: Date, _now: Date) {
  var datetime = new Date(_datetime).getTime()
  var now = new Date(_now).getTime()

  //   if (isNaN(datetime)) {
  //     return ' on ' + _datetime;
  //   }

  if (datetime < now) {
    var milisec_diff = now - datetime
  } else {
    var milisec_diff = datetime - now
  }

  var days = Math.floor(milisec_diff / 1000 / 60 / (60 * 24))
  // var hours = Math.floor(milisec_diff / (1000 * 60 * 60) - days * 24)
  // var minutes = Math.floor(
  //   milisec_diff / (1000 * 60) - days * 24 * 60 - hours * 60
  // )

  if (isNaN(days)) days = 0

  return days || 0
}
export const GetFormulaValue = (formula?: any, value?: number) => {
  if (formula) {
    if (formula.indexOf('{Basic Salary}') != -1) {
      formula = formula?.replace(/{Basic Salary}/g, value.toString())
    }

    if (formula.indexOf('{Standard Wages}') != -1) {
      formula = formula?.replace(/{Standard Wages}/g, value.toString())
    }

    if (formula.indexOf('{Quantity}') != -1) {
      formula = formula?.replace(/{Quantity}/g, value.toString())
    }

    if (formula.indexOf('{Amount}') != -1) {
      formula = formula?.replace(/{Amount}/g, value.toString())
    }

    if (formula.indexOf('{Pre Standard Wages}') != -1) {
      formula = formula?.replace(/{Pre Standard Wages}/g, value.toString())
    }

    if (formula.indexOf('{Required Attendance Days}') != -1) {
      formula = formula?.replace(
        /{Required Attendance Days}/g,
        value.toString()
      )
    }
    if (formula.indexOf('{Prorated Work Day}') != -1) {
      formula = formula?.replace(/{Prorated Work Day}/g, value.toString())
    }
    //replace the payitem
    if (formula.indexOf('{') != -1 && formula.indexOf('}') != -1) {
      let PayItemTags = formula.match(/{(.*?)}/g)
      if (PayItemTags != null && PayItemTags.length > 0) {
        PayItemTags.forEach(element => {
          let payItemId = element?.replace(/{/g, '')?.replace(/}/g, '')
          // var regExp = new RegExp(payItemId, "g");
          formula = formula?.replace(payItemId, '0')
          formula = formula?.replace('{0}', '0')
        })
      }
    }

    //calculate after replace all
    if (formula) {
      try {
        var expression = new String(formula)
          ?.replace(/then/g, '?')
          ?.replace(/else/g, ':')
          ?.replace(/if/g, '')
          ?.replace(/and/g, '&&')
          ?.replace(/or/g, '||')
          ?.replace(/(\(\s*)|(\s*\))/g, '')

        console.log(expression, 'ABC')

        var result = JSON.parse(eval(expression.toString()))

        if (!isNaN(parseFloat(result))) {
          result = Number(parseFloat(result).toFixed(2))
        }
      } catch (e) {
        return 'FORMULA INVALID'
      }
    } else {
      //directly use the amount or quantity
      return true
    }
  }

  return formula
}

export const VerifyTMSFormula = (formula: any, Type?: string) => {
  const valueNum = 1
  const valueStr = 'Yes'
  const standardNumType = [
    '{Shift Hour (Min)}',
    '{PreOT}',
    '{PostOT}',
    '{Work (Min)}',
    '{Actual Work (Min)}',
    '{Raw OT (Min)}',
    '{Late In}',
    '{Early Out}',
    '{Time Off}',
    '{Approved OT (Min)}',
    '{Travel Time}',
    '{Unproductive Time}',
    '{Absent Occurence}',
    '{Late In Occurence}',
    '{Early Out Occurence}',
    '{Incomplete Clocking Occurence}',
    '{Leave Count}',
    '{Sick Leave Count}',
    '{Unpaid Leave Count}',
    '{Lateness (Min)}',
    '{Early Goer (Min)}',
  ]
  const standardStrType = [
    '{Shift}',
    '{Shift Pattern}',
    '{Day Type}',
    '{Leave Type}',
    '{Absenteeism}',
    '{Leave}',
    '{Incomplete Clocking}',
    '{Day of the Week}',
  ]

  if (formula) {
    if (Type === 'Eligibility') {
      const RegexAndOr = /\s(&|or)\s/g
      const statementSymbol = /\s(=|>|>=|<|<=)\s/g
      const RegexStrSymbol = /\s(>|>=|<|<=|[+\-*/](?![^{}]*\}))\s/g

      // Split formula with and / or
      const SplitFormula = formula
        ?.split(RegexAndOr)
        ?.filter(exp => !['or', '&']?.includes(exp))
        ?.flatMap(expression => expression.split(RegexAndOr).filter(Boolean))

      // Loop each section
      for (let index = 0; index < SplitFormula.length; index++) {
        const statement = SplitFormula[index]

        // Return error if no condition sign for value comparison
        if (statement?.split(statementSymbol)?.length !== 3) {
          // eligibility formula should have condition
          return {
            message: 'FORMULA INVALID',
            statement: statement === formula ? '' : statement,
          }
        }

        // Return error if string type treat as number
        if (
          standardStrType?.some(str =>
            statement
              ?.split(statementSymbol)?.[0]
              ?.match(/{(.*?)}/g)
              ?.includes(str)
          ) &&
          (RegexStrSymbol?.test(statement) ||
            statement
              ?.split(statementSymbol)?.[2]
              ?.match(/{(.*?)}/g)
              ?.some(str => standardNumType?.includes(str)) ||
            !isNaN(parseFloat(statement?.split(statementSymbol)?.[2])))
        ) {
          // string formula issue
          return {
            message: 'FORMULA INVALID',
            statement: statement === formula ? '' : statement,
          }
        } else if (
          // Return error if number type treat as string
          standardNumType?.some(str =>
            statement
              ?.split(statementSymbol)?.[0]
              ?.match(/{(.*?)}/g)
              ?.includes(str)
          ) &&
          ((statement?.split(statementSymbol)?.[2]?.match(/{(.*?)}/g)?.length >
            0 &&
            !statement
              ?.split(statementSymbol)?.[2]
              ?.match(/{(.*?)}/g)
              ?.every(str => standardNumType?.includes(str))) ||
            (statement?.split(statementSymbol)?.[2]?.match(/{(.*?)}/g)
              ?.length <= 0 &&
              isNaN(parseFloat(statement?.split(statementSymbol)?.[2]))))
        ) {
          // number formula issue
          return {
            message: 'FORMULA INVALID',
            statement: statement === formula ? '' : statement,
          }
        }
      }
    }

    standardNumType?.map(str => {
      if (formula.indexOf(`${str}`) != -1) {
        formula = formula?.replaceAll(str, valueNum?.toString())
      }
    })

    // CustomList
    if (formula.indexOf('{Minutes}') != -1) {
      formula = formula?.replace(/{Minutes}/g, '')
    }
    formula = formula?.replace(/{(.*?)}/g, `'${valueStr}'`)

    // calculate after replace all {} value
    try {
      var expression = new String(formula)
        ?.replace(/(>=|<=|=)/g, '==')
        ?.replace(/&/g, '&&')
        ?.replace(/or/g, '||')

      var result = JSON.parse(eval(expression.toString()))

      if (!Type && isNaN(parseFloat(result))) {
        // Value only can return number
        return { message: 'FORMULA INVALID', statement: '' }
      }
      if (!isNaN(parseFloat(result))) {
        result = Number(parseFloat(result).toFixed(2))
      }
    } catch (e) {
      // Formula issue
      return { message: 'FORMULA INVALID', statement: '' }
    }
  }
  // No formula pass
  return { message: 'FORMULA VALIDATED', statement: '' }
}

export const convertTimeToEmpGMT = (timeString, timezone) => {
  let serverGMT = 8

  let offsetInMinutes = timezone ? timezone * 60 : 480
  let timezoneDiff = Math.abs(serverGMT - timezone)

  // Break down the time string into its components
  const [date, time] = timeString.split('T')
  const [year, month, day] = date.split('-')
  const [hour, minute, second] = time.split(':')
  const [seconds, milliseconds] = second.split('.')
  const millisecondsValue = parseInt(milliseconds)

  // Calculate the new hour and minute values
  let newHour = parseInt(hour) + Math.floor(offsetInMinutes / 60)
  let newMinute = parseInt(minute) + (offsetInMinutes % 60)

  if (serverGMT > timezone) {
    newHour =
      parseInt(hour) + Math.floor((offsetInMinutes + timezoneDiff * 60) / 60)
    newMinute = parseInt(minute) + ((offsetInMinutes + timezoneDiff * 60) % 60)
  } else {
    newHour =
      parseInt(hour) + Math.floor((offsetInMinutes - timezoneDiff * 60) / 60)
    newMinute = parseInt(minute) + ((offsetInMinutes - timezoneDiff * 60) % 60)
  }

  // Handle cases where the new minute value exceeds 60
  const newHourValue = newHour + Math.floor(newMinute / 60)
  const newMinuteValue = newMinute % 60

  // Construct the new time string

  return `${year}-${month}-${day}T${newHourValue
    .toString()
    .padStart(2, '0')}:${newMinuteValue
    .toString()
    .padStart(2, '0')}:${seconds}.${millisecondsValue
    .toString()
    .padStart(3, '0')}Z`
}

export const convertTimeFormat = timeString => {
  // Break down the time string into its components
  const [date, time] = timeString.split('T')
  const [year, month, day] = date.split('-')
  const [hour, minute, second] = time.split(':')

  // Convert the month number to its corresponding month name
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const monthName = monthNames[parseInt(month) - 1]

  // Convert the hour to 12-hour format and add AM/PM
  let hourValue = parseInt(hour)
  let ampm = 'AM'
  if (hourValue > 12) {
    hourValue -= 12
    ampm = 'PM'
  } else if (hourValue === 0) {
    hourValue = 12
  }

  // Construct the new time string in the desired format
  return `${day} ${monthName} ${year} ${hourValue
    .toString()
    .padStart(2, '0')}:${minute.padStart(2, '0')} ${ampm}`
}

export const dateTimeWithoutParse = timeString => {
  // Extract the date, hours, and minutes.
  const year = timeString.slice(0, 4)
  const month = timeString.slice(5, 7)
  const day = timeString.slice(8, 10)
  const hours = parseInt(timeString.slice(11, 13))
  const minutes = parseInt(timeString.slice(14, 16))

  // Add 8 hours.
  const newHours = (hours + 8) % 24

  // Format the time into 12-hour format.
  const amOrPm = newHours >= 12 ? 'PM' : 'AM'
  const formattedHours = newHours % 12 === 0 ? 12 : newHours % 12

  // Format the date and time into HH:MM A DD MMM YYYY format.
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  return `${formattedHours}:${minutes
    .toString()
    .padStart(2, '0')} ${amOrPm} ${day} ${
    monthNames[parseInt(month) - 1]
  } ${year}`
}

export const extractHour = timeString => {
  const hour = timeString.slice(11, 13)
  const minute = timeString.slice(14, 16)
  const amOrPm = hour >= 12 ? 'PM' : 'AM'
  const formattedHour = hour % 12 === 0 ? 12 : hour % 12

  return `${formattedHour}:${minute} ${amOrPm}`
}
